<template>
  <layout-fullbg>
    <router-view />
  </layout-fullbg>
</template>

<script>
import LayoutFullbg from '@core/layouts/layout-full/LayoutFullbg.vue'

export default {
  components: {
    LayoutFullbg
  }
}
</script>
